import linkedinlogo from "./logos/linkedin-svgrepo-com.svg";
import "./App.css";
import { Swirl, Aurora, Shift, Pipeline, Coalesce } from "ambient-cbg";

function App() {
  let BackgroundComponent = () => {
    switch (Math.round(1 + Math.random() * (2 - 1))) {
      case 1:
        return <Swirl />;
      case 2:
        return <Pipeline />;
      case 3:
        return <Shift />;
      case 4:
        return <Aurora />;
      case 5:
        return <Coalesce />;
      default:
        return <Swirl />;
    }
  };

  return (
    <div className="App">
      <BackgroundComponent />
      {/* <Swirl  /> */}
      <header className="App-header">
        {/* <header> */}
        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        #343028cc
         */}

        <div
          style={{
            border: "5px solid #c0448fcc",
            backgroundColor: "#282c34cc",
            borderRadius: 20,
            padding: 10,
          }}
        >
          <div>
            Hello, I'm <span style={{ color: "magenta" }}>Corey Guest</span>.
          </div>
          <div>I'm a full-stack web developer.</div>
          {/* <div style={{ border: "1px solid green"}}> */}
            <div style={{ marginTop: 5, paddingLeft: 10, paddingRight: 10, display: "flex", justifyContent: "space-evenly", }}>
              <a
                href="https://www.linkedin.com/in/corey-guest-8b65635/"
              >
                <img
                  src={linkedinlogo}
                  className="App-logo"
                  stylalt="logo"
                  alt="Linkedin Profile"
                  style={{verticalAlign: "middle"}}
                /></a>
                
            </div>
            {/* <span style={{ paddingLeft: 10, paddingRight: 10 }}>
              <img src={logo} className="App-logo" alt="logo" />
            </span>
            <span style={{ paddingLeft: 10, paddingRight: 10 }}>Blog</span> */}
          </div>
        {/* </div> */}
      </header>
    </div>
  );
}

export default App;
